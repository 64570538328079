import {ReactElement, ReactNode, useEffect, useState} from "react";
import UserInfo from "@/models/UserInfo";
import {Modal} from "react-bootstrap";
import ChangePasswordModal from "@/components/ChangePasswordModal";
import DeleteAccountModal from "@/components/DeleteAccountModal";
import PayoutProfileModal from "@/components/PayoutProfileModal/PayoutProfileModal";
import {PayoutProfileStatus} from "@/models/PayoutProfile";
import {useSeo} from "@/hoc/Seo/context";
import {SeoPage} from "@/hoc/Seo/provider";

interface AccountSectionProps {
    className: string;
    title: string;
    subtitle?: string;
    children: ReactNode;
    titleClass?: string;
    modify?: boolean;
    showModal?: () => void;
}

const AccountSection = (props: AccountSectionProps): ReactElement => {
    return (
        <div className={`row account__section ${props.className}`}>
            <div className="col-12 col-lg-11 offset-lg-1 text-align-left">
                <div className={`account__header ${props.subtitle ? 'mb-0' : ''}`}>
                    <h4 className={`${props.titleClass || 'account__header__title'} text--primary-color border-0`}>{props.title}</h4>
                    {
                        props.modify ?
                            <>
                                <span> | </span>
                                <span className="account__header__edit font-size-small text--clickable-links"
                                      onClick={() => {if (props.showModal !== undefined) props.showModal()}}
                                >
                                    Modifica
                                </span>
                            </>
                        :
                        null
                    }
                </div>
                {props.subtitle ? <p>{props.subtitle}</p> : ''}
            </div>
            {props.children}
        </div>
    )
}

interface AccountProps {
    user: UserInfo;
    onUserUpdate: () => void;
}

const Account = (props: AccountProps): ReactElement => {
    const seo = useSeo()
    const [showPasswordModal, setShowPasswordModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [showPayoutProfileModal, setShowPayoutProfileModal] = useState<boolean>(false);

    useEffect(() => {
        seo.setSeo(SeoPage.profiloAccount)
    }, [])


    const CardProfile = (): ReactElement => {
        return (
            <>
                {
                    props.user.payoutProfile?.status === PayoutProfileStatus.Complete ?
                        <>
                            <div className="row d-flex card-profile-account card-profile-account__complete">
                                <div className="d-flex card-profile-account__name">
                                    <span className="text--primary-color">
                                        {props.user.payoutProfile.firstName} {props.user.payoutProfile.lastName}
                                    </span>
                                </div>
                                <div className="d-flex card-profile-account__body">
                                    <span className="text--heading-color">
                                        {`IBAN: **** **** **** ${props.user.payoutProfile?.IBAN.substring(props.user.payoutProfile.IBAN.length - 4)}`}
                                    </span>
                                </div>
                                <div className={"d-flex card-profile-account__body pb-3 align-items-center"}>
                                    <i className="icon icon--document-card"/>
                                    <span className="font-size-medium text--heading-color">Documento verificato</span>
                                </div>
                            </div>
                        </>
                    : (props.user.payoutProfile?.status === PayoutProfileStatus.KYCNeeded) ||
                        (props.user.payoutProfile?.status === PayoutProfileStatus.KYCAdditionalDocumentNeeded) ?
                        <>
                            <div className="row d-flex card-profile-account">
                                <div className="d-flex card-profile-account__name">
                                    <span className="text--primary-color">
                                        {props.user.payoutProfile.firstName} {props.user.payoutProfile.lastName}
                                    </span>
                                </div>
                                <div className={"d-flex card-profile-account__body"}>
                                    <i className="icon icon--warning mx-auto"/>
                                </div>
                                <div className={"d-flex card-profile-account__body justify-content-center"}>
                                    <span className="warning-text text--heading-color">Dati mancanti o errati!</span>
                                </div>
                                <div className="d-flex card-profile-account__body justify-content-center">
                                    <span className="font-size-medium warning-to-complete text--clickable-links"
                                          onClick={() => {setShowPayoutProfileModal(true)}}
                                    >
                                        Clicca per completare
                                    </span>
                                </div>
                                <div className={"d-flex card-profile-account__body justify-content-center"}>
                                    <span></span>
                                </div>
                            </div>
                        </>
                        : props.user.payoutProfile?.status === PayoutProfileStatus.KYCPending ?
                        <>
                            <div className="row d-flex card-profile-account">
                                <div className="d-flex card-profile-account__name">
                                    <span className="text--primary-color">
                                        {props.user.payoutProfile.firstName} {props.user.payoutProfile.lastName}
                                    </span>
                                </div>
                                <div className={"d-flex card-profile-account__body"}>
                                    <i className="icon icon--hourglass mx-auto"/>
                                </div>
                                <div className={"d-flex card-profile-account__body justify-content-center"}>
                                    <span className="font-size-large text--heading-color">In attesa di verifica...</span>
                                </div>
                                <div className={"d-flex card-profile-account__body pt-1 justify-content-center"}>
                                    <span></span>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <span
                                onClick={() => setShowPayoutProfileModal(true)}
                                className="btn__text-gradient cursor-pointer text--clickable-links">{"+ Crea profilo di payout"}
                            </span>
                        </>
                }
            </>
        )
    }

    return (
        <div className="account container">
            <AccountSection className="account__dati" title={"Dati profilo"}>
                <div className={"account__field col-12 col-md-6 col-lg-3 offset-lg-1"}>
                    <h5 className={"account__field__label"}>Email</h5>
                    <h5 className={"account__field__value"}>{props.user.email}</h5>
                </div>
                <div className={"account__field col-12 col-md-6 col-lg-3 offset-lg-1"}>
                    <h5 className={"account__field__label"}>Password</h5>
                    <h5 className={"account__field__value"}>••••••••••••••••</h5>
                    <label className="account__header__edit mt-3 text--clickable-links" onClick={()=>{
                        setShowPasswordModal(true)
                    }}>
                        Modifica password
                    </label>
                </div>
            </AccountSection>
            <AccountSection className="account__dati" title={"Profilo di payout"}
                            modify={props.user.payoutProfile?.status === PayoutProfileStatus.Complete}
                            showModal={() => setShowPayoutProfileModal(true)}
            >
                <div className="col-12 col-lg-11 offset-lg-1">
                    {
                        props.user.payoutProfile?
                            <CardProfile />
                            :
                            <span
                                style={{cursor:"pointer"}}
                                onClick={() => setShowPayoutProfileModal(true)}
                                className="btn__text-gradient">{"+ Crea profilo di payout"}
                            </span>
                    }
                </div>
            </AccountSection>
            <AccountSection className="account__delete" title={"Elimina account"}
                            titleClass="account__delete__title"
                            subtitle={"Cliccando su \"ELIMINA ACCOUNT\" tutti i tuoi dati su Ticketag verranno definitivamente cancellati."}>
                <div className="col-12 col-lg-11 offset-lg-1">
                    <button className="account__delete__button btn btn--outlined btn--outlined--red"
                    onClick={() => setShowDeleteModal(true)}
                    >
                        <span
                            className="btn__text-gradient-red">{"Elimina account"}</span>
                    </button>
                </div>
            </AccountSection>
            {
                showPasswordModal ?
                    <Modal show={showPasswordModal}
                           onHide={() => {
                               setShowPasswordModal(false)
                           }}
                           contentClassName={"reset-password-modal__content checkout-modal__content ticket-cards__modal__content"}
                           dialogClassName={"checkout-modal ticket-cards__modal overflow-md-hidden"}
                    >
                        <ChangePasswordModal onClose={() => {
                            setShowPasswordModal(false)
                        }}/>
                    </Modal>
                    :
                    null
            }
            {
                showDeleteModal ?
                    <Modal show={showDeleteModal}
                           onHide={() => {
                               setShowDeleteModal(false)
                           }}
                           contentClassName={"account-change-data-modal__content checkout-modal__content ticket-cards__modal__content"}
                           dialogClassName={"checkout-modal ticket-cards__modal overflow-md-hidden"}
                    >
                        <DeleteAccountModal onClose={() => {
                            setShowDeleteModal(false)
                        }}/>
                    </Modal>
                    :
                    null
            }
            {
                showPayoutProfileModal ?
                    <Modal show={showPayoutProfileModal}
                           onHide={() => {
                               setShowPayoutProfileModal(false)
                           }}
                           contentClassName={"ticket-cards__modal__content"}
                           dialogClassName={"ticket-cards__modal"}
                    >
                        <PayoutProfileModal
                            profile={props.user.payoutProfile}
                            onClose={() => {
                                props.onUserUpdate()
                                setShowPayoutProfileModal(false)
                            }}
                        />
                    </Modal>
                    :
                    null
            }
        </div>
    )
}

export default Account
