import React, {ReactElement, useEffect, useState} from "react";
import Stepper from "@/components/Stepper";
import PayoutProfile, {PayoutProfileStatus} from "@/models/PayoutProfile";
import {useForm} from "react-hook-form";
import UploadDocuments from "@/components/PayoutProfileModal/UploadDocuments";
import {useApi} from "@/hoc/Api/context";
// @ts-ignore
import MaskedInput from 'react-text-mask';
// @ts-ignore
import createIBANMask from 'text-mask-addon-iban';
import Media from "react-media";
import moment from "moment";
// @ts-ignore
import * as iban from "@/core/iban";
import Loader from "@/components/Loader";
import Select, {StylesConfig} from "react-select";
// @ts-ignore
import it from 'react-phone-number-input/locale/it';

import PhoneInput from 'react-phone-number-input'
import {toast} from "react-toastify";

interface EditPayoutProfileProps{
    onClose: () => void;
    profile?: PayoutProfile;
    updateTitle: (title: string) => void;
}

interface IBANInput {
    Iban: string;
}

const EditPayoutProfile = (props:EditPayoutProfileProps): ReactElement => {

    const [step, setStep] = useState<number>(0);
    const [profile, setProfile] = useState<PayoutProfile | undefined>(props.profile);
    const [ibanMask, setIbanMask] = useState<RegExp[]>([]);
    const [phoneNumber, setPhoneNumber] = useState<string>(props.profile?.phoneNumber ?? "");
    const [loading, setLoading] = useState<boolean>(false);
    const api = useApi();

    const countriesOptions = [
        {value: "IT", label: "Italia"},
        {value: "AT", label: "Austria"},
        {value: "BE", label: "Belgio"},
        {value: "CY", label: "Cipro"},
        {value: "CZ", label: "Repubblica Ceca"},
        {value: "DE", label: "Germania"},
        {value: "DK", label: "Danimarca"},
        {value: "ES", label: "Spagna"},
        {value: "FI", label: "Finlandia"},
        {value: "FR", label: "Francia"},
        {value: "GB", label: "Regno Unito"},
        {value: "GR", label: "Grecia"},
        {value: "HU", label: "Ungheria"},
        {value: "IE", label: "Irlanda"},
        {value: "LT", label: "Lituania"},
        {value: "LU", label: "Lussemburgo"},
        {value: "LV", label: "Lettonia"},
        {value: "MT", label: "Malta"},
        {value: "NL", label: "Paesi Bassi"},
        {value: "PL", label: "Polonia"},
        {value: "PT", label: "Portogallo"},
        {value: "RO", label: "Romania"},
        {value: "SE", label: "Svezia"},
        {value: "SK", label: "Slovacchia"},
        {value: "SI", label: "Slovenia"},
        {value: "TR", label: "Turchia"},
        {value: "US", label: "Stati Uniti"},
    ]

    const defaultValue = (): Object => {
        return props.profile?.status ?
            {...props.profile, dateOfBirth: moment(new Date(profile?.dateOfBirth || "")).format("YYYY-MM-DD")} : {};
    }

    const {handleSubmit, register, formState, setValue} = useForm<PayoutProfile>({
        mode: "onChange",
        defaultValues: defaultValue()
    })

    const thirdStepForm = useForm<IBANInput>({
        mode: "onChange",
    })

    const selectStyles: StylesConfig = {
        control: (styles, {isFocused}) => {
            return {
                ...styles,
                backgroundColor: 'white', border: "1px solid rgba(29, 15, 81, 0.1)", borderRadius: 2,
                padding: 0, paddingLeft: 10, minHeight: 34, maxHeight: 34, height: 34,
                boxShadow: "none", color: "#161413", fontSize: 12, cursor: 'pointer',
                transition: "all 0.2s ease-in-out",
                '&:hover': {
                    border: "1px solid black",
                    transition: "all 0.2s ease-in-out",
                }
            }},
        indicatorSeparator: (styles) => ({ ...styles, display: 'none'}),
        indicatorsContainer: (styles) => ({ ...styles, padding: 0}),
        dropdownIndicator: (styles) => ({ ...styles, padding: 0, paddingRight: 5}),
        valueContainer: (styles) => ({ ...styles, padding:0, fontSize: 12, color: "#161413"}),
        menu: (styles) => ({ ...styles, fontSize: 12, color: "#5E7891 !important"}),
        option: (styles, {isFocused, isSelected}) => {
            return {
                ...styles,
                cursor: 'pointer',
                backgroundColor: isFocused ? '#F5F5F5' : isSelected ? '#F5F5F5' : 'white',
                color: isSelected ? '#5E7891' : '#5E7891',
                ':active': {
                    ...styles[':active'],
                    backgroundColor: isFocused ? '#F5F5F5' : isSelected ? '#eaeaea' : 'white',
                },
            }
        },
        singleValue: (styles) => ({ ...styles, color: "#161413", fontSize: 12}),
    }

    useEffect(() => {
        register("country", {required: true});
        register("phoneNumber", {required: true});
        if (profile?.country.length === undefined || profile?.country.length < 1) {
            setValue("country", "" ,{ shouldValidate: true });
        } else {
            setValue("country", profile?.country, { shouldValidate: true });
        }
        thirdStepForm.register("Iban",
            {
                required: true,
                pattern: /^(?:(?:CR|DE|ME|RS|VA)\d{20}|(?:CZ|ES|SE|SK|TN)\d{22}|(?:DK|FI|FO|GL|SD)\d{16}|(?:AT|BA|EE|LT|XK)\d{18}|(?:AE|IL|TL)\d{21}|(?:LY|PT|ST)\d{23}|(?:IT|SM)\d{2}[A-Z]\d{10}[A-Za-z0-9]{12}|(?:HU|PL)\d{26}|(?:AL|CY)\d{10}[A-Za-z0-9]{16}|(?:CH|LI)\d{7}[A-Za-z0-9]{12}|(?:FR|MC)\d{12}[A-Za-z0-9]{11}\d{2}|(?:GB|IE)\d{2}[A-Z]{4}\d{14}|(?:KZ|LU)\d{5}[A-Za-z0-9]{13}|(?:GI|IQ)\d{2}[A-Z]{4}[A-Za-z0-9]{15}|(?:PK|RO)\d{2}[A-Z]{4}[A-Za-z0-9]{16}|(?:PS|QA)\d{2}[A-Z]{4}[A-Za-z0-9]{21}|AD\d{10}[A-Za-z0-9]{12}|AZ\d{2}[A-Z]{4}[A-Za-z0-9]{20}|BE\d{14}|BG\d{2}[A-Z]{4}\d{6}[A-Za-z0-9]{8}|BH\d{2}[A-Z]{4}[A-Za-z0-9]{14}|BR\d{25}[A-Z][A-Za-z0-9]|BY\d{2}[A-Za-z0-9]{4}\d{4}[A-Za-z0-9]{16}|DO\d{2}[A-Za-z0-9]{4}\d{20}|EG\d{27}|GE\d{2}[A-Z]\d{16}|GT\d{2}[A-Za-z0-9]{24}|GR\d{9}[A-Za-z0-9]{16}|HR\d{19}|IS\d{24}|JO\d{2}[A-Z]{4}\d{4}[A-Za-z0-9]{18}|KW\d{2}[A-Z]{4}[A-Za-z0-9]{22}|LC\d{2}[A-Z]{4}[A-Za-z0-9]{24}|LB\d{6}[A-Za-z0-9]{20}|LV\d{2}[A-Z]{4}\d{13}|MD\d{2}[A-Za-z0-9]{20}|MK\d{5}[A-Za-z0-9]{10}\d{2}|MR\d{25}|MT\d{2}[A-Z]{4}\d{5}[A-Za-z0-9]{18}|MU\d{2}[A-Z]{4}\d{19}[A-Z]{3}|NL\d{2}[A-Z]{4}\d{10}|NO\d{13}|SA\d{4}[A-Za-z0-9]{18}|SC\d{2}[A-Z]{4}\d{20}[A-Z]{3}|SI\d{17}|SV\d{2}[A-Z]{4}\d{20}|TR\d{8}[A-Za-z0-9]{16}|UA\d{8}[A-Za-z0-9]{19}|VG\d{2}[A-Z]{4}\d{16}|GE\d{2}[A-Z]{2}\d{16})$/,
                validate: (value: string) => { for(let i = 0; i < value.length; i++) { if (value[i] === "_") { return false } } return true }
            })
        thirdStepForm.setValue("Iban", profile?.IBAN || "")
        if (props.profile?.IBAN) {
            if(iban.isValidCountryCode(props.profile.IBAN.substring(0, 2))){
                setIbanMask(createIBANMask(props.profile.IBAN.substring(0, 2)))
            }
        }
    }, []);

    useEffect(() => {
        if (step === 0) {
            props.profile?.status === PayoutProfileStatus.KYCNeeded ?
                props.updateTitle("Dati di payout mancanti o errati - Fatturazione") :
                props.profile?.status === PayoutProfileStatus.Complete ?
                    props.updateTitle("Modifica dati di payout - Fatturazione") :
                    props.updateTitle("Dati di payout - Fatturazione")
        } else if (step === 2) {
            props.profile?.status === PayoutProfileStatus.KYCNeeded ?
                props.updateTitle("Dati di payout mancanti o errati - IBAN") :
                props.profile?.status === PayoutProfileStatus.Complete ?
                    props.updateTitle("Modifica dati di payout - IBAN") :
                    props.updateTitle("Dati di payout - IBAN")
        }
    }, [step])

    const handleChangeIban = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const planeIban = value.replace(/\s/g, '').replace("_", "");
        if (planeIban.length === 2) {
            if (iban.isValidCountryCode(planeIban.substring(0, 2))) {
                setIbanMask(createIBANMask(planeIban.substring(0, 2)))
            }
        } else if (planeIban.length === 1) {
            setIbanMask([ /[A-Z]/i, /[A-Z]/i ])
        } else if (planeIban.length === 0) {
            setIbanMask([])
        } else if (planeIban.length > 2 && ibanMask.length === 0) {
            if(iban.isValidCountryCode(planeIban.substring(0, 2))){
                setIbanMask(createIBANMask(planeIban.substring(0, 2)))
            }
        }
        thirdStepForm.setValue("Iban", planeIban, {shouldValidate: true})
    }

    const onSubmit = (): void => {
        setLoading(true)
        if (profile === undefined) { return; }
        let finalProfile = profile
        finalProfile.IBAN = thirdStepForm.getValues("Iban")
        finalProfile.dateOfBirth = new Date(profile.dateOfBirth)
        api.UpdatePayoutProfile(finalProfile).then(r => {
            setLoading(false)
            const updatePayoutProfile = {
                time: new Date().getMilliseconds(),
                value: props.profile?.status ?? "draft"
            }
            localStorage.setItem("updatePayoutProfile", JSON.stringify(updatePayoutProfile));
            console.log("set localstorage")
            props.onClose()
        }).catch(e => {
            if (e.response.status === 500) {
                toast.error("Errore server interno, si prega di riprovare più tardi")
            } else {
                toast.error("Si è verificato un errore")
            }
            setLoading(false)
            props.onClose()
        })
    }

    const handleStepOneCompleted = (pProfile: PayoutProfile): void => {
        setLoading(true)
        pProfile.dateOfBirth = new Date(pProfile.dateOfBirth)
        setProfile(pProfile)
        api.UpdatePayoutProfile(pProfile).then( r => {
            setLoading(false)
        }).catch(e => {
            setLoading(false)
        })
        setStep(step + 1)
    }

    const stepOne = (): ReactElement => {
        return <form onSubmit={handleSubmit(handleStepOneCompleted)}>
            <div className={"col-12"}>
                <Media query={{maxWidth: 768}} key={null}>
                    {
                        matches => matches ?
                            <>
                                <div className={"row pb-3"}>
                                    <div className={"col-6 form-group px-3"}>
                                        <label htmlFor={"firstName"}>NOME</label>
                                        <input type="text" className={`form-control`} id={"firstName"}
                                               {...register("firstName", {required: true})}
                                        />
                                    </div>
                                    <div className={"col-6 form-group px-3"}>
                                        <label htmlFor={"address"}>COGNOME</label>
                                        <input type="text" className={`form-control`} id={"lastName"}
                                               {...register("lastName", {required: true})}
                                        />
                                    </div>
                                </div>
                                <div className={"row pb-3"}>
                                    <div className={"col-6 form-group px-3"}>
                                        <label htmlFor={"dateOfBirth"}>DATA DI NASCITA</label>
                                        <input type="date" className={`form-control`} id={"dateOfBirth"}
                                               min="1900-01-01"
                                               max={new Date().toISOString().split("T")[0]}
                                               {...register("dateOfBirth", {required: true})}
                                        />
                                    </div>
                                    <div className={"col-6 form-group px-3"}>
                                        <label htmlFor={"phoneNumber"}>TELEFONO</label>
                                        <PhoneInput
                                            placeholder={"Telefono"}
                                            value={phoneNumber}
                                            defaultCountry={"IT"}
                                            labels={it}
                                            onChange={(ev) => {
                                                setPhoneNumber(ev as string)
                                                setValue("phoneNumber", ev as string, {shouldValidate: true})
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className={"row pb-3"}>
                                    <div className={"col-6 form-group px-3"}>
                                        <label htmlFor={"country"}>NAZIONE</label>
                                        <Select
                                            defaultValue={
                                                profile?.country ? countriesOptions.find(c => c.value === profile?.country)
                                                    : null
                                            }
                                            options={countriesOptions}
                                            placeholder={"Nazione"}
                                            styles={selectStyles}
                                            isClearable={false}
                                            onChange={(ev:any) => {
                                                setValue("country", ev.value, {shouldValidate: true})
                                            }}
                                        />
                                    </div>
                                    <div className={"col-6 form-group px-3"}>
                                        <label htmlFor={"postalCode"}>CAP</label>
                                        <input type="number" className={`form-control`} id={"postalCode"}
                                               {...register("postalCode", {required: true})}
                                        />
                                    </div>
                                </div>
                                <div className={"row pb-5"}>
                                    <div className={"col-6 form-group px-3"}>
                                        <label htmlFor={"address"}>INDIRIZZO</label>
                                        <input type="text" className={`form-control`} id={"address"}
                                               {...register("address", {required: true})}
                                        />
                                    </div>
                                    <div className={"col-6 form-group px-3"}>
                                        <label htmlFor={"city"}>CITTÀ</label>
                                        <input type="text" className={`form-control`} id={"city"}
                                               {...register("city", {required: true})}
                                        />
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className={"row pb-3"}>
                                    <div className={"col-4 form-group px-3"}>
                                        <label htmlFor={"country"}>NAZIONE</label>
                                        <Select
                                            defaultValue={
                                            profile?.country ? countriesOptions.find(c => c.value === profile?.country)
                                                : null
                                            }
                                            options={countriesOptions}
                                            placeholder={"Nazione"}
                                            styles={selectStyles}
                                            isClearable={false}
                                            onChange={(ev:any) => {
                                                setValue("country", ev.value, {shouldValidate: true})
                                            }}
                                        />
                                    </div>
                                    <div className={"col-4 form-group px-3"}>
                                        <label htmlFor={"firstName"}>NOME</label>
                                        <input type="text" className={`form-control`} id={"firstName"}
                                               {...register("firstName", {required: true})}
                                        />
                                    </div>
                                    <div className={"col-4 form-group px-3"}>
                                        <label htmlFor={"address"}>COGNOME</label>
                                        <input type="text" className={`form-control`} id={"lastName"}
                                               {...register("lastName", {required: true})}
                                        />
                                    </div>
                                </div>
                                <div className={"row pb-3"}>
                                    <div className={"col-4 form-group px-3"}>
                                        <label htmlFor={"dateOfBirth"}>DATA DI NASCITA</label>
                                        <input type="date" className={`form-control`} id={"dateOfBirth"}
                                               min="1900-01-01"
                                               max={new Date().toISOString().split("T")[0]}
                                               {...register("dateOfBirth", {required: true})}
                                        />
                                    </div>
                                    <div className={"col-4 form-group px-3"}>
                                        <label htmlFor={"phoneNumber"}>TELEFONO</label>
                                        <PhoneInput
                                            placeholder={"Telefono"}
                                            value={phoneNumber}
                                            defaultCountry={"IT"}
                                            labels={it}
                                            onChange={(ev) => {
                                                setPhoneNumber(ev as string)
                                                setValue("phoneNumber", ev as string, {shouldValidate: true})
                                            }}
                                        />
                                    </div>
                                    <div className={"col-4 form-group px-3"}>
                                        <label htmlFor={"postalCode"}>CAP</label>
                                        <input type="number" className={`form-control`} id={"postalCode"}
                                               {...register("postalCode", {required: true})}
                                        />
                                    </div>
                                </div>
                                <div className={"row pb-5"}>
                                    <div className={"col-4 form-group px-3"}>
                                        <label htmlFor={"address"}>INDIRIZZO</label>
                                        <input type="text" className={`form-control`} id={"address"}
                                               {...register("address", {required: true})}
                                        />
                                    </div>
                                    <div className={"col-4 form-group px-3"}>
                                        <label htmlFor={"city"}>CITTÀ</label>
                                        <input type="text" className={`form-control`} id={"city"}
                                               {...register("city", {required: true})}
                                        />
                                    </div>
                                </div>
                            </>
                    }
                </Media>
            </div>
            <div className={"row justify-content-center"}>
                <div className={"col-auto d-flex justify-content-center"}>
                    <button id="signin-button" className="btn btn--outlined px-4 px-md-5 py-0"
                            onClick={() => {props.onClose()}}
                            style={{lineHeight: "12px", height: "35px"}}
                    >
                        <span className="btn__text-gradient" style={{zIndex: 0}}>Annulla</span>
                    </button>
                </div>
                <div className={"col-auto d-flex justify-content-center"}>
                    <button type="submit"
                            disabled={!formState.isValid}
                            className="btn btn--gradient px-4 px-md-5"
                            style={{lineHeight: "12px", height: "35px"}}
                    >
                        Continua
                    </button>
                </div>
            </div>
        </form>
    }

    const stepTwo = (): ReactElement => {
        return <UploadDocuments
            onClose={props.onClose}
            profile={props.profile}
            onBack={() => setStep(step -1)}
            onForward={() => setStep(step + 1)}
            additionalDocument={false}
            updateTitle={props.updateTitle}
        />
    }

    const stepThree = (): ReactElement => {
        thirdStepForm.trigger("Iban");
        return (
            <form onSubmit={thirdStepForm.handleSubmit(onSubmit)}>
                <div className={"col-12"}>
                    <div className={"row justify-content-center pb-5"}>
                        <div className={"col-10 form-group pb-4"}>
                            <label htmlFor={"iban"}>Inserisci il codice IBAN a cui inviare il saldo.</label>
                        </div>
                        <div className={"col-10 form-group pb-5"}>
                            <label htmlFor={"Iban"}>IBAN</label>
                            <MaskedInput type="text"
                                         mask={ibanMask.length > 0 ? ibanMask : false}
                                         showMask={false}
                                         defaultValue={thirdStepForm.getValues("Iban")}
                                         className={`form-control ${!thirdStepForm.formState.isValid ? "is-invalid" : ""}`}
                                         id={"IBAN"}
                                         onChange={(e: any) => {handleChangeIban(e)}}
                            />
                        </div>
                    </div>
                </div>
                <div className={"row justify-content-center"}>
                    <div className={"col-auto d-flex justify-content-center"}>
                        <button id="signin-button" className="btn btn--outlined px-4 px-md-5 py-0"
                                onClick={() => {setStep(step - 1)}}
                                style={{lineHeight: "12px", height: "35px"}}
                        >
                            <span className="btn__text-gradient">INDIETRO</span>
                        </button>
                    </div>
                    <div className={"col-auto d-flex justify-content-center"}>
                        <button type="submit"
                                disabled={!thirdStepForm.formState.isValid}
                                className="btn btn--gradient px-4 px-md-5"
                                style={{lineHeight: "12px", height: "35px"}}
                        >
                            AVANTI
                        </button>
                    </div>
                </div>
            </form>
        )
    }

    const renderStep = (): ReactElement => {
        switch (step){
            case 0:
                return stepOne()
            case 1:
                return stepTwo()
            case 2:
                return stepThree()
        }
        return <></>
    }

    if (loading) {
        return <div className={'py-5 my-5'}><Loader /></div>
    }

    return(
        <>
            {
                <Stepper
                    currentStep={step}
                    initialStep={step}
                    onChange={(val) => {
                        setStep(val)
                    }}
                    className="ticket-cards__modal__stepper mt-2 mb-3"
                    numSteps={3}
                />
            }
            {renderStep()}
        </>
    )
}

export default EditPayoutProfile;
