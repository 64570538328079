interface colorsVariables {
    background: string;
    linesAndBox: string;
    mainText: string;
}

const variables: colorsVariables = {
    background: 'rgb(255, 255, 255);',  // Change Me
    linesAndBox: 'rgb(228, 226, 233);',  // Change Me
    mainText: 'rgb(94, 120, 145);',   // Change Me
}

export default variables
