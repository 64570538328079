import {ReactElement, useEffect, useState} from "react";
import Loader from "@/components/Loader";
import TicketCard from "@/components/TicketCard";
import MembershipCard from "@/models/MembershipCard";
import {useApi} from "@/hoc/Api/context";
import AddCardModal from "@/components/AddCardModal/AddCardModal";
import {Modal} from "react-bootstrap";
import {toast} from "react-toastify";
import {useSeo} from "@/hoc/Seo/context";
import {SeoPage} from "@/hoc/Seo/provider";

const Tessere = (props: any): ReactElement => {
    const [data, setData] = useState<MembershipCard[] | undefined>(undefined);
    const [addCartModalOpened, setAddCartModalOpened] = useState<boolean>(false);
    const api = useApi();
    const seo = useSeo();

    const getMembershipCards = () => {
        api.GetMembershipCards().then(r => {
            setData(r);
        }).catch(() => {
            toast.error("Network Error, please retry later")
        })
    }
    useEffect(() => {
        seo.setSeo(SeoPage.profiloTessere)
        getMembershipCards()
    }, [])
    const [filter, setFilter] = useState<string>("");
    if (!data) {
        return <Loader/>
    }
    const renderCards = (): ReactElement => {
        return <>
            {
                data.filter(v => {
                    return v.number.includes(filter) || v.fullName.toLowerCase().includes(filter.toLowerCase())
                }).map((m: MembershipCard, i: number) => {
                    return (
                        <TicketCard
                            key={i}
                            classes={i % 2 === 1 ? "col-12 col-md-6 col-lg-5" : "col-12 col-md-6 col-lg-5 offset-lg-1"}
                            uuid={m.uuid}
                            trashable={true}
                            fullName={m.fullName}
                            startColor={m.color1}
                            endColor={m.color2}
                            number={m.number}
                            reductionCode={m.reductionCode}
                            valid={m.valid}
                            pinType={m.pinType}
                            code={m.code}
                            onUpdate={() => {getMembershipCards()}}
                        />
                    )
                })}
        </>
    }

    return (
        <>
            <div className="ticket-cards container">
                <div className="row ticket-cards__header">
                    <div className="col-12 col-lg-7 offset-lg-1 ticket-cards__header__title">
                        <h5 className="ticket-cards__header__title__label">
                            Qui trovi le tue tessere <strong>Sampcard</strong> e <strong>Doriacard</strong>.
                        </h5>
                        <h5 className="ticket-cards__header__title__label">
                            Clicca su <strong>AGGIUNGI TESSERA</strong> per caricare una nuova tessera.
                        </h5>
                    </div>
                    <div className="col-9 col-md-5 col-lg-3">
                        <button id="member-card" className="ticket-cards__header__button btn btn--outlined p-0"
                                onClick={() => {
                                    setAddCartModalOpened(true)
                                }}>
                            <i className="icon icon--member-card icon--member-card--green"/>
                            <span className="btn__text-gradient">{"Aggiungi tessera"}</span>
                        </button>
                    </div>
                </div>

                <div className="row ticket-cards__list">
                    <div className="col-12 col-md-6 col-lg-5 offset-lg-1">
                        {
                            data.length !== 0 &&
                            <div className="ticket-cards__search-box input-group">
                                <input className="form-control" type="text" placeholder="Cerca la tua tessera"
                                       id="cardFilterMobile" value={filter}
                                       onChange={(event) => {
                                           setFilter(event.target.value)
                                       }}/>
                            </div>
                        }

                    </div>
                    <div className="w-100"></div>
                    {renderCards()}
                </div>
            </div>
            {
                addCartModalOpened ?
                    <Modal show={addCartModalOpened}
                           onHide={() => {
                               setAddCartModalOpened(false)
                           }}
                           contentClassName={"ticket-cards__modal__content"}
                           dialogClassName={"ticket-cards__modal"}
                    >
                        <AddCardModal
                            onClose={() => {
                                setAddCartModalOpened(false)
                            }}
                            onComplete={() => {
                            setAddCartModalOpened(false)
                                setData(undefined)
                                api.GetMembershipCards().then(r => {
                                    setData(r);
                                }).catch(() => {
                                    toast.error("Spiacenti, si è verificato un errore")
                                })
                            }}
                        />
                    </Modal>
                    :
                    null
            }
        </>

    )
}

export default Tessere
