import {ReactElement} from 'react'
//@ts-ignore
import footerLogo from "../img/icon/footer_logo.png";
//@ts-ignore
import instagramLogo from "../img/icon/instagram.svg";
//@ts-ignore
import twitterLogo from "../img/icon/twitter.svg";
//@ts-ignore
import facebookLogo from "../img/icon/facebook.svg";
//@ts-ignore
import youtubeLogo from "../img/icon/youtube.svg";
//@ts-ignore
import telegramLogo from "../img/icon/telegram.svg";
//@ts-ignore
import linkedinLogo from "../img/icon/linkedin.svg";
//@ts-ignore
import tiktokLogo from "../img/icon/tiktok.svg";
//@ts-ignore
import twitchLogo from "../img/icon/twitch.svg";
import {useNavigate} from "react-router-dom";
import {useCookies} from "@/hoc/Cookies/Cookies";

interface SectionData {
    title: string;
    links: { text: string, onClick: () => void }[];
}

interface SocialData {
    url: string;
    image: string;
}

const Footer = ({...props}): ReactElement => {
    const navigate = useNavigate()
    const cookies = useCookies()
    const sections: SectionData[] = [
        {
            title: "Supporto",
            links: [
                {
                    text: "Domande frequenti",
                    onClick: ()=>{
                        navigate("/Faq")
                    },
                },
                {
                    text: "Assistenza Sampdoria",
                    onClick: ()=>{
                        window.open("mailto:service@sampdoria.it","_blank")
                    },
                },
            ]
        },
        {
            title:"Ticketag",
            links:[
                {
                    text: "Termini e condizioni",
                    onClick: ()=>{
                        navigate("/Termini-e-Condizioni")
                    },
                },
                {
                    text: "Privacy & Cookie Policies",
                    onClick: ()=>{
                        navigate("/Privacy-Policy")
                    },
                },
                {
                    text: "Preferenze cookies",
                    onClick: ()=>{
                        cookies.openModal()
                    }
                }
            ]
        },
        {
            title:"Link rapidi",
            links:[
                {
                    text: "U.C. Sampdoria",
                    onClick: ()=>{
                        window.open("https://www.sampdoria.it/","_blank")
                    },
                },
                {
                    text: "Biglietteria",
                    onClick: ()=>{
                        window.open("https://www.sampdoria.it/biglietteria/","_blank")
                    },
                },
                {
                    text: "Fidelity Card",
                    onClick: ()=>{
                        window.open("https://www.sampdoria.it/biglietteria/fidelity-card/","_blank")
                    },
                },
            ]
        },
    ]

    const socials: SocialData[] = [
        {
            url: "https://www.instagram.com/sampdoria/",
            image: instagramLogo
        },
        {
            url: "https://it-it.facebook.com/sampdoria/",
            image: facebookLogo
        },
        {
            url: "https://twitter.com/sampdoria",
            image: twitterLogo
        },
        {
            url: "https://www.youtube.com/channel/UCYHKWbpx6eX_ydppBcOIOng",
            image: youtubeLogo
        },
        {
            url: "https://www.tiktok.com/@sampdoria",
            image: tiktokLogo
        }
    ]

    const sectionList = sections.map((s, i) => {
        return (
            <section className="footer__section col-11 offset-1 offset-md-0 col-md-3 mb-4" key={i}>
                <h3 className="sb-white-label text--footer">{s.title}</h3>

                <ul className={"footer__list text--footer"}>
                    {
                        s.links.map((l, k) => {
                            return (
                                <li className="footer__item text--footer" key={k}>
                                    {
                                        <a onClick={l.onClick} className="footer__link nav-link text--footer">{l.text}</a>
                                    }
                                </li>
                            )
                        })
                    }
                </ul>
            </section>
        )
    })

    return (
        <footer className="footer">
            <div className="footer__container container">
                <div className="footer__row row">
                    <div className="footer__logo-box col-11 offset-1 col-md-3 offset-md-0">
                        <a href={"/"}>
                            <picture className="footer__logo">
                                <img alt="Full logo" src={footerLogo}/>
                            </picture>
                        </a>
                    </div>

                    <div className={"col-8 row ms-auto me-auto justify-content-between"}>
                        {sectionList}
                    </div>
                    <hr className="separator col-11 col-md-12 ml-auto mr-auto"/>

                    <div className="col-12 col-md-6 col-lg-5 text-center text-md-left mt-2">
                        <p className="footer__credits text--footer">Ticketag s.r.l. | Sede
                            legale: VIA MARCO ULPIO TRAIANO 11 20149 MILANO | CF e P.IVA 09856600961</p>
                    </div>

                    <section className="col-8 offset-2 col-md-12 offset-md-0 col-lg-3 offset-lg-4 ml-md-auto mt-2">
                        <ul className="footer__social-list navbar-nav flex-row">
                            {
                                socials.map((s, i) => {
                                    return (
                                        <li className="footer__item footer__item--icon text--footer" key={i}>
                                            <a href={s.url} target={"_blank"}
                                               className="footer__link nav-link text--footer">
                                                <img src={s.image} alt={s.url}/>
                                            </a>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </section>

                </div>
            </div>
        </footer>
    )
}

export default Footer
